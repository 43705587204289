.group_container {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}

.search_icon {
  position: absolute;
  margin-left: 3px;
  align-self: center;
}

.search_input {
  border-radius: 3px;
  border: 1px solid lightgrey;
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  font-size: 0.8em;
  font-style: italic;
  color: #343434;
  padding: 2px 5px 2px 25px;
}

.more {
  font-size: 0.8em;
  font-weight: 400;
  margin: 5px 3px 15px;
  cursor: pointer;
  color: #166ed5;
}

.more:hover {
  text-decoration: underline;
}
