.name {
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content_container {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.item {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #343434;
  padding-bottom: 5px;
}
