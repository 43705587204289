.header_container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px;
  flex-grow: 1;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.header_row {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  background-color: #166ed5;
  color: #ffffff;
  padding: 5px;
  font-size: 0.9em;
}

.rows_container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: #ffffff;
  color: #4c4c4c;
  border-bottom: 1pt solid #f6f6f6;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-bottom: 0.3em;
  padding-left: 0.2em;
  margin-bottom: 0.2em;
}

.empty_row {
  color: #4c4c4c;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.row_text {
  flex: 0.2;
  text-align: left;
  align-self: center;
  font-weight: 400;
  font-size: 0.9em;
}

.secondary_row_text {
  font-size: 0.9em;
  text-align: left;
  align-self: center;
  font-weight: 600;
}

.secondary_header_text {
  font-size: 0.9em;
  text-align: left;
  align-self: center;
  font-weight: 600;
  min-width: 4em;
  margin-left: 0.25em;
}

.comments {
  font-size: 0.9em;
  flex: 0.2;
  text-align: left;
  align-self: center;
  font-weight: 800;
  flex: 1;
}

.header_text {
  text-align: left;
  flex: 0.2;
  text-align: left;
  align-self: center;
  font-weight: 600;
  margin-left: 0.5em;
}

.secondary_header_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  color: #4c4c4c;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 400;
  height: 2em;
  background-color: rgb(219, 231, 245);
  border-left: 0.2em solid rgb(219, 231, 245);
  margin-left: 0.25em;
}
.secondary_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: white;
  color: #4c4c4c;
  padding: 5px;
  font-size: 0.9em;
  background-color: #f4f4f4;
  border-left: 0.4em solid rgb(246, 34, 39);
  margin-left: 0.3em;
  margin-bottom: 0.3em;
}

.status {
  color: 'white' !important;
  width: 'fit-content' !important;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-radius: 1em;
  margin-bottom: 0.2em;
}

.underline_text {
  text-decoration: underline;
}
