.chart_container {
  display: flex;
  flex-direction: column;
}

.loading {
  text-align: center;
}

.title-text {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
  padding: 0;
}

.sub-title-text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  color: grey;
  margin: 0 auto;
  padding: 0;
}

.my-pretty-chart-container {
  padding: 0;
  display: flex;
  margin: 0 auto;
}

.skeleton {
  width: 200px;
  height: 240px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

@media only screen and (max-width: 1380px) {
  .title-text {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    padding: 0;
  }
  .my-pretty-chart-container {
    padding: 0;
    display: flex;
    margin: 0 auto;
  }
}
