.form_container {
  width: 42vw;
  margin: auto;
  justify-content: center;
}

.form_content {
  width: 100%;
  min-width: 350px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.input_container {
  min-height: 100px;
}

.input_error_text {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  max-width: 240px;
  color: red;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
}

.divider {
  margin: 15px 0px 10px -1vw;
  width: 44vw;
  min-width: 350px;
  border-top: 0.1px solid #e2e4e9;
}

input {
  transition: background-color 3000s ease-in-out 0s;
}

@media only screen and (max-width: 1200px) {
  .form_container {
    width: 100%;
    max-width: 1200px;
    min-width: 300px;
    margin: auto;
    justify-content: center;
  }
  .form_content {
    width: 100%;
    max-width: 1200px;
    min-width: 300px;
    justify-content: center;
    margin-bottom: 10px;
  }
  .check_box_container {
    margin-left: 15px;
  }
  .divider {
    margin: 15px 0px 10px -15px;
    width: 44vw;
    border-top: 0.1px solid #e2e4e9;
  }
}
