.notification-settings>.title {
  padding: 1em 0 1em 1.2em;
  color: #343434;
}

.notification-settings>.title>h2 {
  font-weight: 600;
}

.notification-settings>.notification-settings-content {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  border-top: 0.03em #CDCDCD solid;
  padding-left: 0;
  color: #343434;
}

.notification-settings>.notification-settings-content>.settings-content {
  margin: 0 auto;
}

.notification-settings>.notification-settings-content>.settings-content>.title {
  font-weight: bold;
  text-align: left;
  margin-left: 1em;
  font-size: 1.1rem;
  margin-bottom: 1.2%;
}

.notification-settings>.notification-settings-content>.settings-content>.recipients {
  width: 90%;
  margin-left: 1%;
}

.notification-settings>.notification-settings-content>.settings-content>.recipients>.recipient-info>.recipient-description {
  margin-right: 5%;
}

.notification-settings>.notification-settings-content>.settings-content>.recipients>.recipient-info {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (min-width: 788px) {
  .notification-settings>.notification-settings-content>.settings-content>.recipients {
    margin-left: 3%;
  }
  .notification-settings>.notification-settings-content>.settings-content>.recipients {
    width: 105%;
    margin-left: 1%;
  }
}