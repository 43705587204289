.main_container {
  width: 400px;
  margin: auto;
  margin-bottom: 20%;
}

.red_text {
  color: #f62227;
}

.delete_buttons {
  width: auto;
}
