.purchaseOrderDetailsModal {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  max-width: 100vw;
  min-width: 60vw;
  height: 100%;
  margin: 0;
}

.po-body {
  padding: 0
}

.po-title {
  width: 100%;
}

.po-header {
  padding: 0;
}

.loading_circle {
  margin-top: 40%;
  margin-bottom: 5%;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-content {
  max-height: none !important;
}