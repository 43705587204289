.content_holder {
  height: auto;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
}

.detail_group {
  flex: 0.35;
}

.table_group {
  flex: 0.65;
  padding: 0px 0px 0px 10px;
}

.title_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  padding: 5px;
  line-height: 30px;
}

.commentBoxContainer {
  margin-top: 1em;
}

.detail_value_input {
  height: 30%;
  width: 100%;
  font-size: 20px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid lightgrey;
  resize: none;
  background: white;
  flex: 1;
  overflow: hidden;
}

.title_label {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
}

.hide_btn {
  margin-left: 20px;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  color: #166ed5;
}

.top_table {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}

.bottom_table {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.part_edit_btn {
  font-size: 16px;
  color: #166ed5;
  cursor: pointer;
}

.cancel_button {
  background-color: #eaeaea;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  color: #343434;
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}
.cancel_button:active,
.cancel_button:hover {
  filter: brightness(75%);
}

.save_button {
  background-color: #166ed5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  color: white;
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}
.save_button:active,
.save_button:hover {
  filter: brightness(150%);
}

.save_button:disabled {
  opacity: 0.3;
}

.modal {
  display: flex;
  justify-content: center;
  min-height: 350px;
  height: 100%;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal_small {
  display: flex;
  justify-content: center;
  min-height: 100px;
  height: 100%;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.backdrop {
  opacity: 0.5 !important;
}

.location_dropdown {
  width: 100%;
  height: 30%;
}

.time_tracking_card_group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.time_tracking_card {
  border: 1px solid black;
  width: 180px;
  height: 80px;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  border-color: lightgrey;
  flex-direction: column;
}

.time_tracking_card_title {
  color: #92929d;
  font-weight: 500;
  font-size: 11px;
}

.time_tracking_card_value {
  font-weight: 500;
  color: #343434;
}

.red_text {
  color: #F62227;
}

.bold_text {
  font-weight: bold;
}

.show_hide_arrow {
  color: #166ed5;
  margin-bottom: 0.2em;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.operation_table {
  padding: 10px 20px;

}

.operation_table_title {
  justify-content: space-between;
  align-items: center;

}

.operation_table_title_text {
  font-size: 24px;
  font-weight: bold;
}

.operation_table_text_button {
  color: #166ED5;
  font-size: 16px;
  font-weight: normal;
}

.operation_table_body {
  margin: 10px 0px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.operation_table_body_header {
  padding: 5px 10px;
  background-color: #166ed5;
  font-size: 0.9em;
  flex: 1;
  gap: 10px;
}

.operation_table_body_header_text {
  color: white;
  font-weight: bold;
}

.operation_table_body_column {
  margin: auto;
  flex: 0.8
}

.operation_table_body_column_wide {
  margin:auto;
  flex: 1.5
}

.operation_table_body_items_container {
  padding: 0px 10px;
}

.operation_table_body_item {
  padding: 5px 0px;
  border-bottom: 1px solid #f6f6f6;
  gap: 10px;  
}

.operation_table_body_item > .operation_table_body_column {
  margin-left: 5px;
}

.operation_table_input {
  line-height: inherit;
  height: auto;

}

.operation_table_input_container {
  min-width: 0px;
}

.operation_table_empty_list_message_text {
  color: #4c4c4c;
  padding: 5px 0px;
  text-align: center;
  margin: auto;
  font-size: 16px;
}