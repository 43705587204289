.alert_card{
    border: 1px solid #6cb444;
    background-color: #f0fef0;
    margin-bottom: 15px;
    border-radius: 3px;
    padding: 15px 20px;
}

h5 {
    font-weight: 500;
}

p {
    font-weight: 400;
}

.toggle {
    padding: 0px !important;
}

.red_text, .red_text:hover {
    color: red;
}