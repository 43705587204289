.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-tabs {
  width: 11em;
}

.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-tabs .tab {
  width: 100%;
}

.tabbedSectionComponent-container.rule-tab-selection > .tabbedSectionComponent-content {
  padding: 0;
  background-color: #ffffff;
}

.alerts-page {
  display: flex;
  margin-top: 7%;
}

.alerts-settings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1% 5% 1% 5%;
}

.last-alert {
  border-bottom: 0.125em solid #E2E2EA;
}

.switch-helper-text {
  font-size: 90%;
  color: #979799;
}

.error-text {
  font-size: 90%;
  margin-top: 1.5%;
  color: #F44336;
}

.inline-bold {
  display: inline;
  font-weight: bold;
}

.time_input {
  height: 50%;
  margin-top: 5px;
  width: 120px;
  margin-right: 1em;
}

.alerts-settings-text {
  width: 40%;
}

.early-expiration {
  width: 120px;
  height: 50%;
  align-self: flex-start;
  margin-right: 1em;
}

.early-expiration-container {
  align-self: flex-start;
  width: 50%;
  margin-left: 5%;
}

.button-container {
  margin: auto;
  margin-top: 4em;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
}

.input-error {
  border-color: #F44336;
}

@media only screen and (min-width: 800px) {
  .alerts-page {
    display: block;
    margin-top: 5%;
  }
}
