.container {
  width: 100%;
  height: 100%;
  max-width: 400px;
}

.image {
  width: 30%;
  justify-self: center;
  align-self: center;
}

.top_section {
  display: flex;
  height: 70%;
  padding-bottom: 5px;
}

.top_left_container {
  height: 100%;
  max-height: 100%;
  width: 70%;
  padding-left: 20px;
  padding-right: 10px;
}

.top_title {
  font-size: 18px;
  font-weight: bold;
  padding-top: 10px;
  word-break: break-word;
  overflow: hidden;
  height: 50%;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.top_rfid_group {
  height: 30%;
  display: flex;
  font-size: 12px;
  line-height: 12px;
}

.top_rfid_data {
  margin: 0;
  padding-left: 5px;
  word-break: break-all;
}

.bottom_section {
  border-top: 2px solid lightgray;
  display: flex;
  padding-top: 5px;
  justify-content: space-evenly;
  text-align: center;
  height: 30%;
  font-size: 12px;
}

.status_group {
  display: flex;
  padding-bottom: 5px;
  height: 20%;
}

.status_style {
  margin: 0px;
  margin-right: 8px;
  font-style: italic;
  font-size: 12px;
}

.bottom_data_group {
  width: 33%;
  padding: 0px 5px 0px 5px;
}
.bottom_data_content {
  margin: 0;
  margin-top: 5px;
  line-height: 12px;
  word-break: break-all;
  font-weight: bold;
  letter-spacing: -1px;
}
