.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.rowWrapToColumn {
  width: 33%;
  min-width: 425px;
  margin-left: -1em;
  margin-right: 1em;
}

.noRowsToShow {
  align-content: center;
}

@media only screen and (min-width: 768px) {
  .rowWrapToColumn {
    margin-left: -1em;
    margin-right: 1em;
  }
}

@media only screen and (min-width: 576px) {
  .itemContainer {
    max-width: 100%;
  }
}
