.inventoryRequests-page {
  display: flex;
  margin-top: 65px;
  margin-left: 15px;
  margin-right: 15px;
  flex-direction: row;
  -webkit-flex-direction: row;
  /* Safari 6.1+ */
  display: -webkit-flex;
  /* Safari */
}

.add-request-button {
  width: 180px;
  height: 110px;
  text-transform: none;
  letter-spacing: .04rem;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
  padding: 1rem 2.2em 1.8rem 1.9rem;
  color: white;
  background-color: #166ED5;
  font-size: .7em;
  flex-direction: row;
  margin: 5px 0 20px 0;
  box-shadow: 0 2px 8px -1px #166ED5;
}

.add-request-button:hover {
  box-shadow: 0 2px 8px 1px #166ED5;
}

.button-logo {
  height: 50px;
  margin-bottom: 1.3em;
}

.container1 {
  text-align: center;
  flex: 0.1;
  flex-direction: column;
  margin: 10px;
  margin-right: 0px;
}

.container2 {
  flex: 0.9;
  margin: 10px;
}

.container3 {
  text-align: justify;
  margin: 10px;
}

@media only screen and (min-width:800px) {
  .inventoryRequests-page {
    display: flex;
    margin-top: 65px;
  }
}