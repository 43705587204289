.checkbox_outer {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 1px;
  border-radius: 2px;
  border: thin solid #DCDCDC;
}

.checkbox_checked {
  height: 100%;
  width: 100%;
  background-color: #1E71D2;
  border-radius: 2px;
}

.checkbox_unchecked {
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 2px;
}
