.dropdown_menu_override {
  font-weight: normal;
  padding: 0;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 9px -2px;
  border: 0;
}

.address_form_container {
  width: 25vw;
  max-width: 350px;
  padding: 4% 4% 0%;
  background-color: #FFFFFF;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 9px -2px
}

.label_text {
  font-weight: bold;
}

.form_title {
  margin-top: 3%;
}

.popper_form_input {
  width: 100%;
  border-radius: 3px;
  margin-bottom: 2%;
}

.address_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5%;
}

.city_container {
  width: 35%;
}

.state_container {
  width: 15%;
}

.zip_code_container {
  width: 25%;
}

.address_button_container {
  display: flex;
  margin-top: 10%;
  float: right;
  margin-right: 15%;
  margin-bottom: 3%;
}

.close_button {
  margin-right: 20%;
  padding: 3%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #166ED5;
  cursor: pointer;
  font-weight: 600;
}

.submit_button {
  padding: 3% 10%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #166ED5;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 600;
}

.email_button_container {
  display: flex;
  float: right;
  margin-right: 15%;
  margin-top: 3%;
}

.email_helper_text {
  padding-left: 4%;
  font-size: 0.8em;
  font-style: italic;
  color: #6C757D;
}

.email_cc_bcc {
  color: #166ED5;
}

.email_send_as_pdf {
  background-color: #FBFAFA;
  color: #166ED5;
  width: 25vw;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2% 4% 2% 0;
  font-weight: 600;
}

.email_form_container {
  width: 25vw;
  max-width: 350px;
  background-color: #FFFFFF;
  height: 270px;
  box-shadow: rgb(0 0 0 / 50%) 0px 2px 9px -2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.email_container {
  padding: 4% 4% 0%;
}

.email_input {
  width: 85%;
  border: 2px solid #E2E2EA;
  border-radius: 3px;
  margin-bottom: 2%;
}

.cc_container {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.cancel_container {
  box-sizing: border-box;
  height: 250px;
  width: 360px;
  border: 1px solid #E2E2EA;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 9px -4px rgba(0,0,0,0.5);
  padding: 10px 15px
}

.cancel_input {
  resize: none;
  width: 100%;
  border-radius: 3px;
  height: 100px;
}

.cancel_title {
  font-size: 0.9em;
  padding-bottom: 5px;
}

.cancel_button_container {
  display: flex;
  float: right;
  margin-right: 15%;
}
