.flex_row {
  display: flex;
  flex-direction: row;
}

.edit_buttons_container {
  gap: 0.8rem;
}

.edit_button {
  flex: 1;
  cursor: pointer;
  height: 1.8rem;
}

.text_button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 1rem;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
}

.text_button:hover {
  color: #0056b3;
}
