.flex_direction_row {
  display: flex;
  flex-direction: row;
}

.paginated_content {
  width: 100%;
  justify-content: space-evenly;
}

.arrow_button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 9px -2px #979799;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
}

.arrow_button:hover {
  box-shadow: 0 2px 9px 2px rgba(0, 0, 0, 0.3);
}

.arrow_button_enabled {
  background-color: rgb(49, 131, 238, 0.9);
  border-color: rgb(49, 131, 238, 0); /* to remove in-built border css */
  color: #fff;
}

.arrow_button_disabled {
  background-color: #ededed;
  border-color: rgb(237, 237, 237, 0); /* to remove in-built border css */
  color: 'grey';
  box-shadow: none;
}
