button {
    background: none;
    border: none;
}
.grid {
    display: grid;
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.title_right_container {
    gap: 40px;
    margin-right: 20px;
    align-items: baseline;
}

.title_right_reload_button {
    color:#737880;
}

.map_control { 
    right: 500px !important;
}

.map_control_collapsed {
    right: 0px !important;
}

.information_collapsible_button {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: calc(500px - 15px) ;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    color: #4A90FF;
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
    z-index: 2;
    transition: 500ms ease-in-out;
}

.information_collapsible_button_collapsed {
    right:  5px;
    transition:  500ms ease-in-out;
}

.information_container {
    background-color: white;
    position: absolute;
    right: 0px;
    max-height: 100%;
    height: 100%;
    overflow-y:auto;
    overflow-x:visible;
    max-width: 500px;
    width: 500px;
    transition: width 500ms ease-in-out;
    z-index: 1;
    border-left: 1px solid #e2e2ea;
}

.information_container.information_container_collapsed {
    width: 0%;
    animation: fadeOut 500ms;
    animation-fill-mode: both;
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    50% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

