.mapboxgl-canvas {
    width: 100% !important;
    height: 100% !important;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.map_container {
    width: 100%;
    height: 100%;
    max-height: 100%;

    position: relative
}

.map_tool_container {
  border: 1px solid #e2e2ea;
  border-radius: 8px;
}

.map_control {
  position: absolute;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 1;
  overflow: hidden;
  right: 0px;
  top: 0px;
  transition: 500ms ease-in-out;

}

.map_control_button {
  color: #4a90ff;
  background-color: rgba(256,256,256, 0.6 );

  width: 35px;
  height: 33px;
}

.map_control_button:not(:last-child) {
  border-right: 1px solid #e2e2ea;
}

.map_control_button:hover {
  background-color: #f7f7f7;
}

.color_indicator_container {
  display:flex;
  flex-direction: column;
  gap:5px;
  position: absolute;
  background-color: rgba(256,256,256, 0.6 );
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 5px 10px;
  z-index: 1;
  overflow: hidden;
  right: 0px;
  bottom: 0px;
  transition: 500ms ease-in-out;
}

.color_indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.color_icon {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 100%;
}

.color_label {
  font-weight: 500;
}