.dropdown_container {
  position: relative;
}

.dropdown_menu_container {
  position: absolute;
  background-color: transparent;
  padding-bottom: 30px;
  width: 100%;
  z-index: 2147483647;
}

.dropdown_menu {
  margin: 0px;
  background-color: white;
  margin-top: 5px;
  padding: 0px;
  border: 1px solid #e2e2ea;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 150px;
}

.dropdown_item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  font-weight: 400;
  word-break: break-all;
}

.dropdown_item:hover {
  background-color: #f1f1f2;
}

.dropdown_no_result_message {
  text-align: center;
  color: #b2b2ba;
  padding: 1rem;
  font-weight: 400;
  word-break: break-all;
  margin: auto;
}

.dropdown_container > textarea {
  cursor: pointer;
  caret-color: transparent;
}