.toggle-floor-plan-button {
    border: none;
    background-color: unset;
    font-size: 16px;
    color: #166ed5;
    cursor: pointer;
    line-height: 30px;
    font-weight: 700;
}

.toggle-floor-plan-button:focus {
    outline: none;
}
