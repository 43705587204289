.input_text_enabled {
  text-align: left;
  color: black;
}

.input_text_disabled {
  text-align: left;
  color: gray;
}

.margin_maker {
  margin-top: 10%;
}

.md-form {
  font-weight: bold;
}

.user_profile_title {
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #343434;
  border-bottom: 2px lightgray solid;
  border-top: 10px lightgray solid;
}

.profile_icon {
  margin-right: 10px;
  margin-left: 3px;
}

.swap_button {
  cursor: pointer;
  margin-right: 10px;
}

.profile_title_text {
  font-size: 0.8em;
}

.profile_form_buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 0.3em;
}

.change_password {
  color: #166ed5;
  margin-top: 0.5rem;
  cursor: pointer;
  margin-right: 1rem;
}

.change_password_title {
  text-align: left;
  padding-bottom: 0em;
  font-weight: bold;
}

.input_password {
  text-align: center;
  color: black;
}

.input_password:focus {
  color: black;
}

.change_password_btn {
  width: 40%;
}

.change_password_alerts {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.change_password_alerts > h4 {
  text-align: left;
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.change_password_alerts > p {
  margin-bottom: 2px;
  padding-top: 3px;
}

.profile_card {
  background-color: #fafafa;
  border: 2px lightgray solid;
  /* display: -ms-flexbox; */
  display: flex;
}

input:disabled {
  border-bottom: none !important;
}

input[type='file'] {
  border: none;
}

#givenNameInput > .md-form {
  display: inline-block;
}

.responsive_container {
  margin: 0 auto;
  background-color: white;
}

.responsive_container img {
  border-radius: 0.25rem;
  width: 100%;
}

.main_card {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .responsive_container {
    min-width: 60%;
    /* cap container min width */
    max-width: 60%;
    /* cap container max width */
    position: relative;
    overflow: hidden;
  }
  .responsive_container:before {
    display: block;
  }

  .responsive_container img {
    max-height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .user_profile_title {
    font-size: 1.8em;
  }

  .margin_maker {
    margin-top: 8%;
  }
}
