.content_holder > .title {
  padding: 1em 0 1em 1.2em;
  color: #343434;
  border-bottom: 0.003em #cdcdcd solid;
}

.content_holder.dashboardPage {
  margin-top: 1%;
}

.content_holder > .title > h2 {
  display: inline-block;
  font-weight: 900;
  vertical-align: super;
}

.dashboard_card {
  overflow-y: auto;
}

.overall_container {
  width: 100%;
}

.top_container {
  height: 4em;
}

.flex_direction_row {
  display: flex;
  flex-direction: row;
}

.tile_container {
  display: flex;
  justify-content: space-evenly;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 0.5em;
}

.item_description {
  clear: both;
  height: 1.5em;
  padding-top: 0.4em;
  margin-top: 0.1em;
  font-weight: bold;
}

.pagination_container {
  display: flex;
  margin-left: auto;
  padding-top: 0.6em;
  margin-right: 0.5em;
  clear: both;
}

.dropdown_icon {
  display: flex;
  justify-content: space-evenly;
  padding: 0.5em;
}