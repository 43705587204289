.title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  padding: 0px 26px;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.button {
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
}

.discard_button {
  background-color: #EEEEEE;
}

.create_button {
  margin-left: 10px;
  background-color: #166ED5;
  color: #FFFFFF;
}

.close_button {
  cursor: pointer;
}
