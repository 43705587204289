.modal-content {
  flex: 1;
}

.create_work_order-modal-header {
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 !important;
    border-bottom: 1px solid #E2E2EA !important;
}

.create_work_order-modal-title {
    flex: 1;
}
.create_work_order-modal-body {
    padding: 0px;
    display: flex;
}
.create_work_order-modal-footer {
    padding-left: 30px;
    padding-right: 30px;
}

