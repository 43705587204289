.textarea {
    border: 1.5px solid #E2E2EA;
    padding: 5px 8px;
    font-size: 14px;
    color: #343434;
    border-radius: 5px;
    resize: none;
    line-height: 28px; /* has to be number of pixel for auto resize calculation */
    overflow: hidden;
    background-color: white;
    vertical-align:top;
    width: 100%;
}

.error {
    border-color: red;
}

.textarea:focus {
    overflow-x:hidden;
    overflow-y: auto;
}
.textarea:disabled {
    background-color: #EDEDF2;
}

.no_wrap{
    white-space: nowrap;
    overflow: hidden;
}

.block_with_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1.5px solid transparent;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 28px;
    background-color: white;
    width: 100%;
    word-break: break-word;
}

.error_message{
    color: red;
    font-size: 14px;
    margin-top: 5px;
}
