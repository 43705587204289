.input_container {
    position: relative;
    min-width: 220px;

}

.input {
    border: 1.5px solid #E2E2EA;
    padding: 5px 51px 5px 8px;
    font-size: 14px;
    color: #343434;
    border-radius: 5px;
    resize: none;
    line-height: 28px;
    overflow: hidden;
    background-color: white;
    vertical-align:top;
    width: 100%;
    height: 41px;
    caret-color: transparent;
    cursor: pointer;
}

.input_with_no_icon {
    padding: 5px 8px;
}

.input_error {
    border-color: red;
}

.error_message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.input_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    top: 0px;
    right: 3px;
    cursor: pointer;
}

.input_icon_container > svg {
    color: #166ED5;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 33px;
    margin: auto;
}

.input_icon_container > svg:hover {
    opacity: 0.8;
    background-color: #e2e2ea;

}

.input_icon_container > svg:first-child {
    color: #E2E2EA;
}

.input_icon_container > svg:first-child:hover {
    color: white;
}

.input_icon_container > svg:last-child {
    color: #166ed5;
}

.input_icon_container > svg:last-child:hover {
    color: #166ed5
}

.read_only_text {
    border: 1.5px solid transparent;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    line-height: 28px;
    width: 100%;
    word-break: break-word;
}