.filter_tab_container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .filter_tab_label {
    font-size: 12px;
    color: #343434;
    padding-top: 3px;
    padding-right: 5px;
    font-weight: 500;
    margin: 5px;
  }
  
  .filter_tab_list {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
  }
  
  .filter_tab_item_group {
    padding-left: 10px;
    padding-right: 5px;
    margin: 5px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 1.8em;
    background-color: #EBEBED;
  }
  
  .filter_tab_item_title {
    font-size: 12px;
    font-weight: 500;
    color: #14396a;
    padding-right: 15px;
  }
  
  .filter_tab_item_close_icon {
    cursor: pointer;
    width: 15px !important;
    margin-left: auto;
    font-size: 12px;
    color: #14396a;
    font-weight: 500;
  }
  
  .filter_tab_more_count {
    font-size: 12px;
    margin: 5[x];
    color: #343434;
    font-weight: 500;
  }

  .filter_action_btn {
    cursor: pointer;
    font-size: 12px;
    margin: 5px;
    color: #166ed5;
    font-weight: 500;
  }