.grid {
    display: grid;
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_column {
    display:flex;
    flex-direction: column;
}

.page_frame {
    border: 1px solid #e2e2ea;
    border-top: 12px solid #c8cbd3;
    background-color: white;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    grid-template-columns: minmax(1fr, auto);
    grid-template-rows: auto 1fr;
    flex-grow: 1;

}

.title_container{
    border-bottom: 2px solid #E2E2EA;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title_icon{
    border: 4px solid;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.title {
    font-size: 20px;
    font-weight: 600;
    color: #343434;
}


.page_content_container {
    grid-template-columns: minmax(350px, 30%) auto;
    grid-template-areas:
     'left-content right-content';
}

.left_content_container {
    grid-area: left-content;
    border-right: 2px solid #E2E2EA;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content max-content;
    gap: 10px;
    padding: 20px 10px 50px 10px;
}

.edit_button_container {
    justify-content: flex-end;
}

.edit_buttons_container {
    gap: 0.8rem;
}

.edit_button {
    flex: 1;
    cursor: pointer;
    height: 1.8rem;
}

.detail_image {
    /* max-width: 200px; */
    width: 60%;
    margin: auto;
    vertical-align: middle;
}

.primary_fields {
    grid-template-columns: 5% 45% 40% 5% 5%;
    align-items: baseline;
    row-gap: 10px;
    margin-bottom: 20px;
}

.primary_label {
    grid-column: 2;
    text-align: right;
    font-weight: bold;
    color: #343434;
    font-size: 1.125rem;
    text-align: right;
    line-height: 1.75rem;
    line-break: normal;
    padding-right: 5px;

}

.primary_value_container {
    grid-column: 3 / 4;
    display: flex;
    flex-basis: 100px;
    line-height: 1.75rem;

}

.primary_loading {
    grid-column: 2/5;
    padding-left: 20px;
    padding-right:20px;
}

.dropdown_container {
    grid-column: 4 / 6;
    justify-content: flex-end;
    padding-right: 6px;

}

.normal_fields {
    grid-template-columns: repeat(20, 5%);
    align-items: baseline;
    row-gap: 10px;
}

.normal_label {
    grid-column: 3 / span 7;
    text-align: right;
    font-weight: bold;
    color: #343434;
    font-size: 0.875rem;
    text-align: right;
    line-height: 1.75rem;
    line-break: normal;
}

.normal_value_container {
    padding-left: 12px;
    grid-column: span 7;
    display: flex;
}

.normal_loading {
    grid-column: 3 /19;
}

.right_content_container {
    grid-area: right-content;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "tabs"
        "tab-content";
}

.tabs {
  flex: 1;
  flex-wrap: wrap;
  background-color: #f9f9f9;
}

.tab_link {
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 5px 25px 8px 25px;
    font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid #e2e2ea;
    white-space: nowrap;
    width: 100px;
    text-align: center;
}

.tab_link_active, .tab_link_active:hover {
    color: #343434;
    background-color: white;
    border-right-color: #e2e2ea;
    border-left-color: #e2e2eA;
    border-bottom-color: transparent;
}

.tabs > .tab_link:nth-child(1){
    border-left-color: transparent;
}

.tabs > .tab_link:last-child {
    border-right-color: #e2e2ea;
}

.tabs > .tab_link.tab_link_active:last-child{
    border-right-color: transparent;
}

.tabs_empty_space {
    background-color: transparent;
    flex-grow:1;
    border-bottom: 1px solid #e2e2ea;
}

.tab_content {
    grid-area: tab-content;
}

.image_loading {
    height: 200px;
    margin-top: 20px;
}

@media screen and (max-width: 850px) {
    .page_content_container {
        grid-template-columns: auto;
        grid-template-areas: 'left-content' 'right-content';

    }
    .left_content_container {
        border-right: none;
        border-bottom: 1px solid #E2E2EA;
    }
}
