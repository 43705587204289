.grid {
    display: grid;
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_column {
    display:flex;
    flex-direction: column;
}

.page_frame {
    border: 1px solid #e2e2ea;
    border-top: 12px solid;
    background-color: white;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    grid-template-columns: minmax(1fr, auto);
    grid-template-rows: auto 1fr;
    flex-grow: 1;
}

.fixed_height {
    height: calc(100vh - 70px - 40px);
    max-height: calc(100vh - 70px - 40px);
    min-height: calc(100vh - 70px - 40px);
}

.title_container {
    border-bottom: 2px solid #e2e2ea;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title_icon {
    border: 4px solid;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.title {
    font-size: 20px;
    font-weight: 600;
    color: #343434;
}

.title_right_container {
    margin-left: auto;
}

.overflow_hidden {
    overflow: hidden;
}
