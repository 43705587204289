.title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  text-align: right;
}

.title_total {
  font-size: 0.7em;
  font-weight: bold;
}

.header_amount {
  font-size: 1.2em;
}

.close_icon {
  color: #166ED5;
  cursor: pointer;
  width: 30px;
}

.po_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border-color: #8A4EE399;
  margin-right: 5%;
}

.po_title_container {
  display: flex;
  align-items: center;
  width: 70%;
}

.status_box {
  margin: 0 0 0.3% 1.5%;
  padding: 0.5% 2% 0.3% 2%;
  font-size: 0.45em;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  border-radius: 2.4px;
}

.buyer_section {
  text-align: right;
  width: 50%;
}

.buyer_text {
  padding-right: 5%;
}

.bold_text {
  font-weight: bold;
}

.po_identifier {
  font-weight: bold;
  font-size: 0.9em;
}

.po_info_section {
  background-color: #FBFAFA;
  border-bottom: 2px solid #E9ECEF;
}

.po_info_input_text {
  border: 1.5px solid #E2E2EA;
  border-radius: 3px;
  height: 30px;
  padding-left: 4px;
}

.po_info_input_text:focus {
  border: 1.5px solid #166ED5;
  border-radius: 3px;
}

.header_container {
  height: 100px;
}

.po_info_input_container {
  width: 27%;
  height: 56px;
  display:flex;
  flex-direction: column;
}

.po_info_input_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2.5% 6.5% 2.5%;
}

.po_info_title_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2% 2.5% 2.5% 2.5%;
}

.po_info_input_dropdown {
  border: 1.5px solid #E2E2EA;
  border-radius: 3px;
  height: 30px;
}

.po_info_input_dropdown:focus {
  border: 1.5px solid #166ED5;
  border-radius: 3px;
}

.po_info_address_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.po_info_edit_button {
  color: #166ED5;
  cursor: pointer;
}

.bottom_section {
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  display: flex;
  justify-content: space-between;
}

.remarks_container {
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 35%;
}

.remarks_input {
  resize: none;
  border: 1.5px solid #E2E2EA;
  border-radius: 3px;
  height: 100px;
  padding: 0.3em;
}

.totals_container {
  width: 50%;
  background-color: #FBFAFA;
  display:flex;
  flex-direction: column;
  padding: 1.8em 5% 1em 0;
  margin-right: 4%;
}

.totals_label {
  text-align: right;
  width: 30%;
}

.totals_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8em;
}

.totals_amount {
  width: 35%;
  text-align: right;
}

.totals_input { 
  width: 35%;
  text-align: right;
  border: 1.5px solid #E2E2EA;
  border-radius: 3px;
}

.total_amount {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.4em;
  width: 35%;
  justify-content: flex-end;
}

.freight_text {
  width: 35%;
  text-align: right;
  font-style: italic;
}

.currency_label {
  width: 15%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
}

.discount_dropdown {
  width: 15%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
  border: 1.5px solid #E2E2EA;
  border-radius: 3px;
}

.totals_title {
  text-align: right;
  width: 30%;
  font-weight: bold;
  font-size: 1.4em;
}

.footer_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.purple_button {
  height: 34px;
  background-color: #8A4EE3;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1% 3%;
  margin: 0 1%;
  border-radius: 3px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 0.9em;
}

.gray_button {
  height: 34px;
  background-color: #EEEEEE;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1% 3%;
  margin: 0 1%;
  border-radius: 3px;
  font-weight: bold;
  font-size: 0.9em;
}

.more_button {
  height: 34px;
  background-color: #EEEEEE;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1% .7%;
  margin: 0 1%;
  border-radius: 3px;
  font-weight: bold;
}

.unsaved_changes {
  display: flex;
  align-items: center;
  font-style: italic;
  font-weight: 600;
  color: #979799;
  height: 34px;
}

.button_container {
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: flex-end;
}

.item_order_more_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: #166ED5;
  color: #166ED5;
  width: 1.4em;
  height: 1.4em;
  border-radius: 23px;
  margin-left: 1em;
  padding: 10px 0px;
  margin-top: 23.5px;
  cursor: pointer;
}

.item_order_container {
  padding: 4% 2.5% 2.5% 2.5%;
}

.item_order_title {
  padding-bottom: 1%;
  font-weight: bold;
}

.item_order_description {
  display: flex;
  align-items: center;
  font-style: italic;
  color: #979799;
}

.item_order_comments {
  line-height: 1.5em; 
  margin-top: 0.5em;
}

.item_order_options {
  padding: 0;
  height: 35px;
}

.item_order_options_container {
  padding: 0;
  min-width: 150px;
}

.item_order_options_text {
  padding-left: 10px;
  font-size: 0.9em;
}

.dropdown_option_container {
  border-bottom: 2px solid #E2E2EA;
  width: 100%;
  padding-left: 10px;
}

.dropdown_option_text {
  margin: 7px 0px 5px 0px;
  font-weight: 500;
}

.dropdown_sub_text {
  border-bottom: 1px;
  margin-bottom: 7px;
  color: #979799;
  font-size: 0.95em;
  font-style: italic;
}

.error_container {
  margin-top: 1em;
}
