.image_upload_component {
  height: 14em;
  width: auto;
  max-width: 200px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  border: 1.5px dashed #979797;
  border-radius: 5px;
  padding-left: 2em;
  padding-right: 2em;
}

.image_upload_component_text {
  align-self: center;
  text-align: center;
}

.image_preview_container {
  min-height: 14em;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
}

.image_preview {
  height: auto;
  width: 100%;
}

.error_container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0em 2em 1em 2em;
  color: red;
}

.choose_another_button {
  font-weight: 400;
  text-align: center;
  background-color: transparent;
  letter-spacing: 0.03rem;
  padding: 0.5em;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: white;
  font-size: 0.9em;
  margin-bottom: 10px;
  background-color: #166ed5;
  display: block;
  box-shadow: 0px 3px 9px 0px slategrey;
}
