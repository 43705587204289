.icon_button {
  min-width: 40px;
  min-height: 40px;
  font-size: 24px;
  padding: 4px;
}
.dropdown_toggle {
  width: 181px;
  height: 40px;
  padding: 0px;
  background-color: white !important;
  color: black;
  margin: 0px;
  margin-left: 0px;
  margin-right: 0px !important;
  box-shadow: none;
  border: 1.11px solid #c9c9c9;
  border-radius: 3px;
}

.dropdown_toggle_filter_panel {
  width: 100%;
  height: 32px;
  padding: 0px;
  background-color: white !important;
  color: black;
  margin: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  box-shadow: none;
  margin-bottom: 4px;
  margin-top: 4px;
}

.dropdown_toggle_filter_panel:hover,
.dropdown_toggle:hover,
.dropdown_toggle:focus {
  background-color: #e2d3f8 !important;
  box-shadow: none !important;
  border-color: #c9c9c9 !important;
}

.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #e2d3f8 !important;
  box-shadow: none !important;
}

.dropdown_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown_item:hover,
.dropdown_item:focus,
.dropdown_item.active {
  background-color: #e2d3f8 !important;
  color: black !important;
}

.title {
  margin-right: 15px;
}

.primary_color {
  color: #8a4ee3;
}

.dropdown_container {
  padding-right: 0.4em;
  padding-left: 1em;
  box-sizing: border-box;
  width: 181px;
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
}

.dropdown_container_filter_panel {
  padding-right: 1em;
  padding-left: 1em;
  box-sizing: border-box;
  width: 181px;
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
}

.dropdown_text_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdown_text {
  font-size: 12.4px;
  font-weight: 600;
  text-transform: none;
}

.dropdown_arrow {
  height: 100%;
  align-content: center;
}
