.container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.left_component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.right_component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
