.tracking_departments_links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px;
  flex-grow: 1;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.tracking_links_header {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  background-color: #166ed5;
  color: #ffffff;
  padding: 5px 5px 5px 10px;
  font-size: 0.9em;
  font-weight: 600;
}
.tracking_departments_links_detail {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.tracking_department_link_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: #ffffff;
  color: #4c4c4c;
  border-bottom: 1pt solid #f6f6f6;
  padding-top: 5px;
  padding-bottom: 5px;
}

.tracking_department_link_row_empty {
  color: #4c4c4c;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.time_duration {
  font-size: 0.9em;
  flex: 0.2;
  text-align: left;
  align-self: center;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.time_detail {
  text-align: left;
  flex: 0.2;
}

.show_button {
  text-transform: capitalize;
  text-decoration: underline;
  color: #166ed5;
  cursor: pointer;
  font-weight: 500;
}

.time_tracking_table {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.time_tracking_header_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: rgb(219, 231, 245);
  color: #4c4c4c;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
}
.time_tracking_data_row {
  margin: 1px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: white;
  color: #4c4c4c;
  padding: 5px;
  font-size: 14px;
}
.time_tracking_status {
  display: flex;
  width: 75px;
  justify-content: center;
  align-items: content;
  text-transform: capitalize;
  color: white;
  font-size: 12px;
  border-radius: 20px;
}

.status {
  color: 'white' !important;
  width: 'fit-content' !important;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-radius: 1em;
  margin-bottom: 0.2em;
}

.underline_text {
  text-decoration: underline;
}
