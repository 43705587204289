.inv_modal_container {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  min-width: 625px;
  height: 100%;
  margin: 0;
}

.inv-body {
  padding: 0px;
  width: 100%;
}

.inv-title {
  width: 100%;
}

.inv-header {
  padding: 0px 10px 0px 25px;
  height: 100px;
  width: 100%;
}

.inv-footer {
  border-top: 1.62px solid #e2e2ea;
  height: 61px;
  background: #ffffff;
  box-shadow: 0px -1px 8px -5px rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 0px 6px;
}

.loading_message {
  font-style: italic;
}
