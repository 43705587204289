.summary_tile {
  cursor: pointer;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;

  width: 25%;
  max-width: 15em;
  min-width: 215px;

  height: 98%;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 1em;
}

.summary_tile:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.55) !important;
}

.filter_disabled {
  opacity: 0.3;
}

.summary_tile_body {
  padding: 0em;
}

.dept_indicator_image {
  width: 10px;
  height: 10px;
  margin-bottom: 0.2em;
  margin-right: 0.2em;
}

.summary_tile_title {
  height: 2em;
  overflow: hidden;
  text-align: center;
  font-weight: 500;
  font-size: 0.8em;
  padding: 0.4em;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

.summary_tile_content {
  background-color: 'white';
  height: 5em;
}

.summary_tile_top_content {
  padding: 0.2em;
  margin-top: 0.8em;
  padding-bottom: 0.2em;
  height: 100%;
  text-align: center;
}

.top_content_number {
  font-size: 2em;
  font-weight: 500;
}

.top_content_text {
  font-size: 0.8em;
}

.badge_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.status_badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.5em;
  font-size: 0.8em;
  padding: 0.01em 0.8em 0.01em 0.8em;
}

.status_badge_text {
  padding-top: 0.2em;
}

.status_badge_count {
  margin-right: 0.3em;
  color: white;
  border-radius: 1em;
  padding: 0.1em 0.8em 0em 0.8em;
  max-height: 1.6em;
}

.horizontal_line {
  width: 25%;
  border-width: 0.3em;
  margin-bottom: 2em;
  border-bottom-style: solid;
  margin-top: 1em;
  border-color: #d9d9d9;
}

.border_top {
  border-top: 0.1em solid #d9d9d9;
}

.right_column_content {
  vertical-align: middle;
}

.status_count {
  width: 25%;
  margin-right: 1%;
  margin-left: 0.4em;
  float: left;
  text-align: right;
  overflow: hidden;
}

.status_name {
  padding-left: 0.3em;
  float: left;
  text-align: left;
  width: 67%;
  overflow: hidden;
}

p {
  margin-bottom: 0;
}
