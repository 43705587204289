.flex_row {
  display:flex;
  flex-direction: row;
}

.flex_column {
  display:flex;
  flex-direction: column;
}

.additional_info_container {
  width: 100%;

}
.title_container {
  justify-content: space-between;
  align-items: center;
}

.title_text{
  color: #343434;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
  font-size: 1rem;
}

.edit_button_container {
  height: 40px;
  align-items: center;
}

.text_button {
  background: none;
  border: none;
  color: #007bff;
  margin-left: auto;
  font-size: 1rem;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
}

.text_button:hover {
  color: #0056b3;
}

.additional_info_fields_container {
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1.5rem;
  flex: 1;
}

.additional_info_field {
  min-width: 250px;
  flex: 0.30 250px 30%;
}

.additional_info_input_container {
  width: 60%;
  min-width: 100px;
  max-width: 200px;

}

.additional_info_field:last-child {
  min-width: 100%;
}

.additional_info_remark_input_container {
  width: 50%;
  min-width: 250px;
}

.additional_info_remark_input_container_not_edit {
  width: 100%;
}

.label {
  color: #343434;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
  margin-bottom: 0.2em;
}

.status_text {
  color: #fff;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
  align-self: center;
  text-transform: capitalize;
  margin: auto;
}

.status_container {
  padding: 0.1em 2em;
  width: 100px;
  border-radius: 0.2em;
  font-size: 0.9em;
  margin-top: 0.2em;
  height: 25px;
}

.feature_container {
  padding: 2.5rem;
  gap: 2rem;
}

.table_container {
  padding: 1.5rem 0;
}

.table_headers{
  margin: 0;
  padding: 0 0 0.5rem 1rem;
  border-bottom: 1px solid #E2e2ea;
  flex: 1;
  gap: 3rem;
  font-size: 0.875rem;

}

.table_headers > li {
  list-style-type: none;
  flex: 1;
  color: #343434;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
  margin-top:auto;
  margin-bottom: auto;
  min-width: 10px;
}

.table_headers > .row_number_column {
  flex: 0.3;
}

.table_headers > li {
  flex: 1.5;
}

.table_headers > .more_button_column {
  flex: 0.25;
}

.table_fields {
  margin: 0;
  padding: 0;

  font-size: 0.875rem;
}

.table_fields > li {
  border-bottom: 1px solid #e2e2ea;
  list-style-type: none;

}

.table_fields > li > ul {
  margin: 0;
  padding: 1rem 0 1rem 1rem;
  display: flex;
  flex-direction: row;
  flex: 1;
  list-style-type: none;
  gap: 3rem;
}

.table_fields > li > ul > li {
  flex: 1;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
  list-style-type: none;
  min-width: 10px;
}

.table_fields > li > ul > li {
  flex: 1.5;

}

.table_fields > li > ul > .row_number_column {
  flex: 0.3;
  color: #343434;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
}

.table_fields > li > ul > .more_button_column {
  flex: 0.25;
}

.table_field_link {
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
  color: #007bff;
  cursor: pointer;
}

.table_field_link:hover{
  text-decoration: underline;
  color: #0056b3;

}

.table_button {
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  padding: .5rem .8rem;
  background: none;
  background-color: #f1f1f2;
  border: none;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
  border-radius: 3px;
}

.table_button:hover{
  opacity: 0.5;
}

.table_button > p {
  color: #343434;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
}

.table_button_add_icon {
  width: 1.2rem !important;
  height: 1.2rem !important;
  color: #007bff;
  margin-right: 10px;
}

.icon {
  border: 1.5px solid #007bff;
  border-radius: calc(100%);
  font-size: 1.5rem !important;
  color: #007bff;
  padding: 2px;
  cursor: pointer;
}

.empty_list_row {
  padding-top: 30px;
  padding-bottom: 6px;
  justify-content: center;
  align-items: center;

}

.empty_list_row > p {
  font-weight: bold;
  color: #b2b2ba;
}

.icon:hover {
  border-color: #0056b3;
  color: #0056b3;
}

.toggle {
  padding: 0px !important;
}

.red_text, .red_text:hover {
  color: red;
}

.blue_text, .blue_text:hover{
  color: #007bff;
}

.step_number {
  flex: 0.75 !important
}

.loading_bar {
  max-width: 200px;
}

.create_container  {
  height: 100%;
  padding: 20vh 10% 20vh 10%;
  align-items: center;
}

.creation_img {

  width: 50%;
  min-width: 400px;
}

.creation_text_container {
  font-weight: bold;
  margin-top: 5vh;
  align-items: center;
  justify-content: center;
}

.creation_text_container > p {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #343434;
  margin-top: 20px;

}

.creation_text_container > .text_button{
  font-size: 18px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.action_buttons_container {
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px 40px;
  border-top: 2px solid #E2E2EA
}

.action_button {
  width: 100px;
  border: none;
  padding: 5px 16px;
  background-color: #258fe0;
  border-radius: 3px;
  font-weight: bold; /* changing this to bold from 500 to temporary font-weight issue on Chrome */
  color: white;

}

.action_button:hover {
  opacity: 0.7;
}

.action_button_cancel {
  background-color: #F1F1F2;
  color: #343434;
}

.backdrop {
  opacity: 0.5 !important;
}

.dropdown_style {
  width: 100%;
  margin-bottom: 1em;
  height: 2.5em;
}

.button {
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #eaeaea;
  margin-right: 4%;
  width: 10em;
  text-transform: none;
  letter-spacing: 0.04rem;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  font-size: 0.9em;
}

.cancel_button {
  background-color: #eaeaea;
  color: #343434;
}
.cancel_button:active,
.cancel_button:hover {
  filter: brightness(75%);
}

.confirm_button {
  background-color: #166ed5;
  color: white;
}
.confirm_button:active,
.confirm_button:hover {
  filter: brightness(150%);
}

.confirm_button:disabled {
  opacity: 0.3;
}

.modal_header {
  padding: 2rem 2rem 1rem 2rem;
}

.modal_title {
  font-weight: 600;
}

.modal_body {
  padding: 1.5rem;
}

.modal_footer {
  padding: 1.5rem;
}

.modal_input_label {
  color: #343434;
}

.modal_select_component:disabled {
  opacity: 0.5;
}
