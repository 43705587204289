.grid {
    display: grid
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.header_container{
    color: #343434;
    flex:1;
    letter-spacing: 0.5px;
}

.header_close_button{
    width: 35x;
    height: 35px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: none;
    border: none;
    color: #1871fe;
}

.header_close_button:hover {
    opacity: 0.7;
}

.header_close_button_icon {
    font-size: 2rem !important;
}

.header_title_container {
    align-items: center;
    gap: 15px;
}

.header_title_icon_container {
    border: 4.5px solid #d6eae6;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_title_container > p {
    font-weight: bold;
    font-size: 1.2rem;
}

.footer_container {
    gap: 20px;
    color: #343434;
    letter-spacing: 0.5px;

}

.footer_container > button {
    background: none;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 3px;
    padding: 8px 25px;
    letter-spacing: inherit;
}

.footer_container > button:disabled {
    opacity: 0.2
}

.footer_container > button:hover {
  opacity: 0.7;
}


.footer_container> button:first-child {
    background-color: #EEEEEE;
}

.footer_container > button:last-child {
    color: white;
    background-color: #008E73;
}

.modal {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: none !important;
    min-width: 700px;
    max-width: none;
    width: 64%;
    height: 100%;
    margin: 0;
}

.body_container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    letter-spacing: 0.5px;
    color: #343434;
}

.work_order_information {
    padding: 20px 40px;
    background-color: #fbfafa;
    row-gap: 20px;
}

.work_order_information > p {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
}

.input_group_list {
    margin-top:10px;
    grid-template-columns: repeat(5, 16% );
    column-gap: 5%;
    row-gap: 20px;
}

.attribute_label {
    font-weight: bold;
    font-size: 0.85rem;
}

.label_asterisk {
    color: red;
    margin-left: 5px;
}

.bom_table_list {
    flex: 1;
    padding: 20px 40px;
    gap: 20px;
}

.remark_container {
    width: 40%;
}

.remark_container > p {
    font-weight: bold;
    font-size: 1rem;
}

@media screen and (max-width: 2400px) {
    .input_group_list {
        grid-template-columns: repeat(4, 19%);
        column-gap: 8%;
    }
}

@media screen and (max-width: 2100px) {
  .input_group_list {
        grid-template-columns: repeat(3, 27%);
        column-gap: 9.5%;
    }
}
