.modal-container {
  width: 44vw;
  max-width: 44vw;
  min-width: 350px;
  height: 20vh;
}

.delete-modal-container {
  width: 38vw;
  max-width: 38vw;
  min-width: 350px;
  height: 20vh;
}

.modal-header {
  margin-left: -7px;
  margin-bottom: -10px;
  border-bottom: 0;
  font-size: 25px;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
}

.cancelButton {
  color: #166ed5;
  text-transform: none;
  letter-spacing: 0.04rem;
  cursor: pointer;
  padding: 0.5rem 0.75rem 0.5rem 0.625rem;
  margin: 0px 10px;
  font-size: 1em;
}

.confirmButton {
  background-color: #166ed5;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #166ed5;
  color: white;
  width: 100%;
  letter-spacing: 0.04rem;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.75rem 0.5rem 0.625rem;
  font-size: 1em;
}

.confirmDangerButton {
  background-color: #f62227;
  box-shadow: 0 0.0625rem 2.5rem -1.25rem #166ed5;
  color: white;
  width: 100%;
  letter-spacing: 0.04rem;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 0.5rem 0.75rem 0.5rem 0.625rem;
  font-size: 1em;
}
